/* ScreenOuter */

.pc-nav-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #F1F1F3;
    background-color: white;
}

/* ScreenOuter End */