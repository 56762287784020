.image {
    object-fit: fill;
}

.top-round {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.bottom-round {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.border-top {
    border-width: 1px;
    border-top: 1px solid #F1F1F3;
}

.modal-bottom {
    padding-bottom: 0;
}

.ant-modal .ant-modal-content {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.ant-picker-calendar .ant-picker-panel {
    border-top: 0;
}

.blue-range {
    background-color: rgba(252, 215, 3, 0.8);
    color: black;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    padding: 8px;
    display: inline-block;
    text-align: center;
}
