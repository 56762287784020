html, body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 300;
    src: url('./assets/font/SpoqaHanSansNeo-Light.otf') format('woff2'),
    url('./assets/font/SpoqaHanSansNeo-Light.otf') format('woff'),
    url('./assets/font/SpoqaHanSansNeo-Light.otf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    src: url('./assets/font/SpoqaHanSansNeo-Regular.otf') format('woff2'),
    url('./assets/font/SpoqaHanSansNeo-Regular.otf') format('woff'),
    url('./assets/font/SpoqaHanSansNeo-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 500;
    src: url('./assets/font/SpoqaHanSansNeo-Medium.otf') format('woff2'),
    url('./assets/font/SpoqaHanSansNeo-Medium.otf') format('woff'),
    url('./assets/font/SpoqaHanSansNeo-Medium.otf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    src: url('./assets/font/SpoqaHanSansNeo-Bold.otf') format('woff2'),
    url('./assets/font/SpoqaHanSansNeo-Bold.otf') format('woff'),
    url('./assets/font/SpoqaHanSansNeo-Bold.otf') format('truetype');
}

.clickable {
    cursor: pointer;
    position: relative;
}

.clickable:hover::before {
    /*content: "\f0e7"; !* 좌측 메뉴 아이콘 *!*/
    font-weight: 900;
    position: absolute;
    left: -20px; /* Adjust the position as needed */
}

.bottom-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
