.basic-type {
    display: flex;
    /*flex-wrap -> 반응형 동작*/
    flex-wrap: wrap;
}

.inner-basic-type {
    margin-top: 0;
}

@media (max-width: 350px) {
    .basic-type {
        display: flow-root;
        margin-top: 10px;
    }

    .inner-basic-type {
        margin-top: 10px;
    }
}

